import React from 'react';
import classNames from 'classnames';
import { CommentCountCompact } from '../../../comments/components/comment-count-compact';
import {
  getViewCount,
  getCommentCount,
  getViewCountFormatted,
  getCommentCountFormatted,
} from '../../store/post-counters/post-counters-selectors';
import { Counter } from '../counter';
import { type CounterProps } from '../counter/counter';
import { useSelector } from '../runtime-context';
import ViewCount from '../view-count';
import ViewCountCompact from '../view-count-compact';
import styles from './post-stats.scss';

type Props = {
  postId: string;
  displayIcons: boolean;
  className?: string;
  showViewCount?: boolean;
  showCommentCount?: boolean;
  postCommentsDisabled: boolean;
};

export const PostStats = ({
  postId,
  displayIcons,
  className,
  showViewCount = true,
  showCommentCount = true,
  postCommentsDisabled,
}: Props) => {
  const { viewCount, totalComments, viewCountFormatted, totalCommentsFormatted } = useSelector(
    (state) => ({
      viewCount: getViewCount(state, postId),
      totalComments: getCommentCount(state, postId),
      viewCountFormatted: getViewCountFormatted(state, postId),
      totalCommentsFormatted: getCommentCountFormatted(state, postId),
    }),
  );
  const Views = displayIcons ? ViewCountCompact : ViewCount;
  const Count = displayIcons
    ? CommentCountCompact
    : (props: CounterProps) => <Counter i18nKey="comment-count.label" {...props} />;
  const showCommentStats = showCommentCount && (totalComments > 0 || !postCommentsDisabled);

  return (
    <div className={classNames(styles.container, 'post-stats', className)} data-hook="post-stats">
      {showViewCount && (
        <Views count={viewCount} countFormatted={viewCountFormatted} className={className} />
      )}
      {showCommentStats && (
        <Count
          showZero
          count={totalComments}
          countFormatted={totalCommentsFormatted}
          className={className}
        />
      )}
    </div>
  );
};
