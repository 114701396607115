import memoizeOne from 'memoize-one';

import {
  isLayoutNameTextOnImage,
  ITEM_COMPONENT_LIST_ITEM,
  ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
  ITEM_COMPONENT_MOBILE_TOI,
  ITEM_COMPONENT_POST,
  LAYOUT_CARD_LARGE,
  LAYOUT_CARD_MEDIUM,
  LAYOUT_CARD_PROFILE,
  LAYOUT_CARD_SMALL,
  LAYOUT_FULL_POST,
  LAYOUT_GRID,
  LAYOUT_LIST,
  LAYOUT_LIST_LARGE,
  LAYOUT_LIST_MEDIUM,
  LAYOUT_MOBILE,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_GRID,
  LAYOUT_PG_GRID_INTERMEDIATE,
  LAYOUT_PG_GRID_LARGE,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
  LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_SIDE_BY_SIDE_MOBILE,
  LAYOUT_SLIDER,
  LAYOUT_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_TEXT_ON_IMAGE_MOBILE,
  LAYOUT_TEXT_ON_IMAGE_SMALL,
  POST_LIST_COMPONENT_MASONRY,
  POST_LIST_COMPONENT_MOBILE,
  POST_LIST_COMPONENT_SIMPLE,
  SECTION_POST_LIST,
  SECTION_RELATED_POSTS,
  type Layout,
  type LayoutName,
  type MixedLayoutType,
  type Section,
} from '@wix/communities-blog-client-common';
import {
  type ComponentKey,
  type ComponentsContextValue,
} from '../components/components-provider/components-context';

type LineCount = {
  title: number;
  description: number;
};

export type LayoutItemConfig = {
  displayFooterIcons: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideDescription?: boolean;
  lineCount?:
    | {
        withCover: LineCount;
        withoutCover: LineCount;
      }
    | LineCount;
};

type LayoutConfigDefinition = {
  listComponentName: ComponentKey | undefined;
  itemComponentName: ComponentKey | undefined;
  itemConfig: LayoutItemConfig;
};

const LAYOUT_CONFIG = {
  [LAYOUT_CARD_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 3, description: 3 },
        withoutCover: { title: 3, description: 3 },
      },
    },
  },
  [LAYOUT_CARD_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: { title: 3, description: 3 },
        withoutCover: { title: 3, description: 3 },
      },
    },
  },
  [LAYOUT_CARD_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: { title: 2, description: 2 },
        withoutCover: { title: 2, description: 2 },
      },
    },
  },
  [LAYOUT_CARD_PROFILE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: { title: 2, description: 2 },
        withoutCover: { title: 2, description: 2 },
      },
    },
  },
  [LAYOUT_FULL_POST]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_POST,
    itemConfig: {
      displayFooterIcons: false,
    },
  },
  [LAYOUT_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 0, description: 3 },
        withoutCover: { title: 0, description: 3 },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: { title: 3, description: 2 },
        withoutCover: { title: 2, description: 2 },
      },
    },
  },
  [LAYOUT_GRID]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 3, description: 0 },
        withoutCover: { title: 3, description: 4 },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 2, description: 0 },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 2, description: 0 },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_ONE_COLUMN_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 0, description: 3 },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 0, description: 3 },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_TOI,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: { title: 2, description: 0 },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 0 },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 0 },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 0 },
    },
  },
  [LAYOUT_PG_CARD_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_PG_SIDE_BY_SIDE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_PG_SIDE_BY_SIDE_RIGHT]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_PG_GRID]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 4 },
    },
  },
  [LAYOUT_PG_GRID_INTERMEDIATE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 4 },
    },
  },
  [LAYOUT_PG_GRID_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 4 },
    },
  },
  [LAYOUT_PG_ONE_COLUMN]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_SLIDER]: {
    itemComponentName: undefined,
    listComponentName: undefined,
    itemConfig: {
      hideFooter: true,
      hideHeader: true,
      hideDescription: true,
      lineCount: { title: 3, description: 3 },
      displayFooterIcons: true,
    },
  },
  [LAYOUT_LIST]: {
    itemComponentName: undefined,
    listComponentName: undefined,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_LIST_MEDIUM]: {
    itemComponentName: undefined,
    listComponentName: undefined,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 3 },
    },
  },
  [LAYOUT_LIST_LARGE]: {
    itemComponentName: undefined,
    listComponentName: undefined,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: { title: 3, description: 3 },
    },
  },
} as const satisfies Record<Layout, LayoutConfigDefinition>;

export type LayoutConfig = NonNullable<ReturnType<typeof getLayoutConfig>>;
export type LayoutConfigListComponent = ComponentsContextValue[NonNullable<
  LayoutConfig['listComponentName']
>];
export type LayoutConfigItemComponent = ComponentsContextValue[NonNullable<
  LayoutConfig['itemComponentName']
>];

export const getLayoutConfig = (layoutType: MixedLayoutType) => {
  return LAYOUT_CONFIG[layoutType];
};

export const getLineCounts = (
  { lineCount }: LayoutItemConfig,
  isWithCover: boolean | undefined,
) => {
  if (!lineCount) {
    throw new Error('lineCount is not defined');
  }

  if ('withCover' in lineCount) {
    return isWithCover ? lineCount.withCover : lineCount.withoutCover;
  }

  return lineCount;
};

const mobileSettings = [
  'title-color',
  'TOI-title-color',
  'description-color',
  'TOI-description-color',
  'overlay-background-color',
  'border-color',
  'background-color',
  'TOI-background-color',
  'post-container',
  'hover-container',
  'link-hashtag-hover-fill',
  'link-hashtag-hover-color',
  'pagination-active',
  'description-fill',
  'TOI-description-fill',
  'description-background-color',
  'TOI-description-background-color',
];
const useMobileSettings = (mobile: boolean, section: Section, className: string) => {
  if (
    mobile &&
    (section === SECTION_POST_LIST || section === SECTION_RELATED_POSTS) &&
    mobileSettings.includes(className)
  ) {
    if (className.startsWith('TOI')) {
      return className.replace('TOI', 'TOI-mobile');
    }
    return `mobile-${className}`;
  }
  return className;
};

export const createFeedClassNameGenerator = memoizeOne(
  <S extends Section>(section: S, mobile: boolean) =>
    (...classes: string[]) =>
      classes.map(
        (className) =>
          `blog-post-${section}-${useMobileSettings(mobile, section, className)}` as const,
      ),
);

export const getFeedColorClassName = <T extends string>(
  layoutName: LayoutName | undefined,
  name: T,
) => `${isLayoutNameTextOnImage(layoutName) ? 'TOI-' : ''}${name}` as const;
