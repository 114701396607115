import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useAuth from '../../hooks/use-auth';
import { createLikeButtonMessage } from '../../services/create-like-button-message';
import {
  getIsLiked as getIsPostLiked,
  getIsLikeInProgress as getIsPostLikeInProgress,
  getLikeCount as getPostLikeCount,
  getLikeCountFormatted as getPostLikeCountFormatted,
} from '../../store/post-likes/post-likes-selectors';
import { Counter } from '../counter';
import LikeButton from '../like-button';
import { useActions, useSelector } from '../runtime-context';
import styles from './like-button-with-count.scss';

type Props = {
  className?: string;
  postId: string;
  showZero?: boolean;
  switchCounterAndButton?: boolean;
};

export const LikeButtonWithCount = ({
  className,
  showZero = false,
  switchCounterAndButton = false,
  postId,
}: Props) => {
  const actions = useActions();
  const { forPublicUser } = useAuth();

  const handleLikeClick: () => void = forPublicUser(() => actions.incrementPostLikeCount(postId));

  const { likeCount, likeCountFormatted, isLikeInProgress, isLiked } = useSelector((state) => ({
    likeCount: getPostLikeCount(state, postId),
    likeCountFormatted: getPostLikeCountFormatted(state, postId),
    isLikeInProgress: getIsPostLikeInProgress(state, postId),
    isLiked: getIsPostLiked(state, postId),
  }));
  const { t } = useTranslation();
  const postLikesMessage = createLikeButtonMessage({
    t,
    likeCount,
    isLiked,
    isComment: false,
  });
  const ariaLive = isLikeInProgress ? 'polite' : 'off';

  return (
    <button className={classNames(styles.button, className)} onClick={handleLikeClick}>
      <span aria-live={ariaLive} className={styles.srOnly}>
        {postLikesMessage}
      </span>
      <span
        className={classNames(
          styles.flexContainer,
          switchCounterAndButton && styles.switchCounterAndButton,
        )}
        data-hook="like-button-with-count__like-count"
      >
        <Counter
          className={classNames(styles.count, 'like-button-with-count__like-count')}
          count={likeCount}
          countFormatted={likeCountFormatted}
          showZero={showZero}
          ariaHidden={true}
        />
        <LikeButton isLiked={isLiked} isDisabled={isLikeInProgress} />
      </span>
    </button>
  );
};
