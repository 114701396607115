import React, { Children } from 'react';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { getCurrentUser } from '@wix/communities-blog-client-common';
import { PermissionContext } from '../../context/permission-context';
import { getPermissionFlags } from '../../selectors/permission-selectors';
import createPermissionChecker from '../../services/create-permission-checker';
import { connect } from '../runtime-context';

function PermissionsProvider({ children, permissions }) {
  return (
    <PermissionContext.Provider value={permissions}>
      {Children.only(children)}
    </PermissionContext.Provider>
  );
}

PermissionsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  permissions: PropTypes.object,
};

const createPermissions = memoizeOne(
  createPermissionChecker,
  (a, b) => isEqual(a[1], b[1]) && isEqual(a[2], b[2]),
);

const mapRuntimeToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const permissionFlags = getPermissionFlags(state);
  const permissions = createPermissions(state, currentUser, permissionFlags, new Date().getTime());
  return {
    permissions,
  };
};

export default connect(mapRuntimeToProps)(PermissionsProvider);
