import { permissionsChecker } from '@wix/communities-blog-universal/dist/src/permissions-checker';
import { getPost } from '../selectors/post-selectors';
import createPermissionFlags from './create-permission-flags';

export default function createPermissionChecker(state, user, permissionFlags = null, key) {
  const flags = permissionFlags || createPermissionFlags(state);

  const checker = permissionsChecker(flags, user, (resource) => {
    if (resource.postId) {
      resource = getPost(state, resource.postId);
    }
    return resource;
  });

  if (key) {
    checker._key = key;
  }

  return checker;
}
