import { createStylesParams, type IStyleParam, StyleParamType } from '@wix/tpa-settings';
import { type CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { ContentSizing, postContentStyleParams } from '@wix/communities-blog-client-common';
import { resolveDefaultStyleParamValue } from '@app/common/style-params';
import * as LEGACYSTYLEPARAMS from './legacyStylesParams';

export const customCssVars: CustomCssVarsFn = (tpaParams) => {
  return {
    'postContent-contentWidthInPxOrPercent': contentWidth(),
  };

  function contentWidth(): `${number}px` | `${number}%` {
    const contentSizing: ContentSizing =
      tpaParams.styleParams.numbers[postContentStyleParams['postContent-contentSizing'].key];
    const contentSizingInPx =
      tpaParams.styleParams.numbers[postContentStyleParams['postContent-contentWidthInPx'].key];
    const contentSizingInPercent =
      tpaParams.styleParams.numbers[
        postContentStyleParams['postContent-contentWidthInPercent'].key
      ];

    return contentSizing === ContentSizing.Fixed
      ? `${contentSizingInPx}px`
      : `${contentSizingInPercent}%`;
  }
};

type IStylesParams = {
  // DISPLAY
  showAuthorName: StyleParamType.Boolean;
  showAuthorPicture: StyleParamType.Boolean;
  showPostPublishDate: StyleParamType.Boolean;
  showReadingTime: StyleParamType.Boolean;
  showPostTitle: StyleParamType.Boolean;
  showPostUpdatedDate: StyleParamType.Boolean;
  showCategoryLabel: StyleParamType.Boolean;
  /** Classic only */
  showMenu: StyleParamType.Boolean;
  /** Should behave on Studio same as showMenu (sets menu visibility) */
  showMenuCategoryLabels: StyleParamType.Boolean;
  /** Classic only */
  showMenuLoginButton: StyleParamType.Boolean;
  /** @deprecated Legacy, Classic only */
  showMenuSearch: StyleParamType.Boolean;
  showTags: StyleParamType.Boolean;
  showMoreOptionsMenu: StyleParamType.Boolean;
  showPostRating: StyleParamType.Boolean;
  showCommentCount: StyleParamType.Boolean;
  showViewCount: StyleParamType.Boolean;
  showLikeCount: StyleParamType.Boolean;

  showShare: StyleParamType.Boolean;
  showShareIconFacebook: StyleParamType.Boolean;
  showShareIconX: StyleParamType.Boolean;
  showShareIconLinkedIn: StyleParamType.Boolean;
  showShareIconLink: StyleParamType.Boolean;
  showShareIconPrint: StyleParamType.Boolean;
  showSharePluginPinterest: StyleParamType.Boolean;
  showSharePluginX: StyleParamType.Boolean;

  showRelatedPosts: StyleParamType.Boolean;
  showRelatedPostsLabel: StyleParamType.Boolean;
  showRelatedPostsLabelType: StyleParamType.Number;
  showRelatedPostsSeeAllLink: StyleParamType.Boolean;

  // DESIGN
  navigationTextFont: StyleParamType.Font;
  navigationTextColor: StyleParamType.Color;
  navigationTextActiveColor: StyleParamType.Color;
  navigationBackgroundColor: StyleParamType.Color;

  textParagraphFont: StyleParamType.Font;
  textParagraphColor: StyleParamType.Color;
  textQuoteFont: StyleParamType.Font;
  textQuoteColor: StyleParamType.Color;
  textLinksAndHashtagsColor: StyleParamType.Color;
  textH1Font: StyleParamType.Font;
  textH1Color: StyleParamType.Color;
  textH2Font: StyleParamType.Font;
  textH2Color: StyleParamType.Color;
  textH3Font: StyleParamType.Font;
  textH3Color: StyleParamType.Color;
  textH4Font: StyleParamType.Font;
  textH4Color: StyleParamType.Color;
  textH5Font: StyleParamType.Font;
  textH5Color: StyleParamType.Color;
  textH6Font: StyleParamType.Font;
  textH6Color: StyleParamType.Color;

  ratingsTextFont: StyleParamType.Font;
  ratingsTextColor: StyleParamType.Color;
  ratingsStarFilledColor: StyleParamType.Color;
  ratingsStarEmptyColor: StyleParamType.Color;

  showTagsLabel: StyleParamType.Boolean;
  tagLayout: StyleParamType.Number;
  buttonTagAlignment: StyleParamType.Number;
  buttonTagTextFont: StyleParamType.Font;
  buttonTagTextColor: StyleParamType.Color;
  buttonTagTextHoverColor: StyleParamType.Color;
  buttonTagBackgroundColor: StyleParamType.Color;
  buttonTagBackgroundHoverColor: StyleParamType.Color;
  buttonTagBorderColor: StyleParamType.Color;
  buttonTagBorderHoverColor: StyleParamType.Color;
  buttonTagBorderWidth: StyleParamType.Number;
  buttonTagBorderRadius: StyleParamType.Number;
  buttonTagVerticalSpacing: StyleParamType.Number;
  buttonTagHorizontalSpacing: StyleParamType.Number;
  buttonTagVerticalPadding: StyleParamType.Number;
  buttonTagHorizontalPadding: StyleParamType.Number;
  textTagAlignment: StyleParamType.Number;
  textTagTextFont: StyleParamType.Font;
  textTagTextColor: StyleParamType.Color;
  textTagTextHoverColor: StyleParamType.Color;
  textTagVerticalSpacing: StyleParamType.Number;
  textTagHorizontalSpacing: StyleParamType.Number;

  postBorderColor: StyleParamType.Color;
  postBorderWidth: StyleParamType.Number;
  postBackgroundColor: StyleParamType.Color;
  postAreaBackgroundColor: StyleParamType.Color;
  postButtonTextColor: StyleParamType.Color;
  postButtonBackgroundColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  // #region Display - Related posts

  showAuthorName: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorName,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorNameMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showAuthorPicture: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showAuthorPicture,
        mobileParamName: LEGACYSTYLEPARAMS.showAuthorPictureMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostPublishDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostPublishDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostPublishDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showReadingTime: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showReadingTime,
        mobileParamName: LEGACYSTYLEPARAMS.showReadingTimeMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostTitle,
        mobileParamName: LEGACYSTYLEPARAMS.showPostTitleMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostUpdatedDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostUpdatedDate,
        mobileParamName: LEGACYSTYLEPARAMS.showPostUpdatedDateMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCategoryLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCategoryLabel,
        mobileParamName: LEGACYSTYLEPARAMS.showCategoryLabelMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showTags: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showTags,
        mobileParamName: LEGACYSTYLEPARAMS.showTagsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMoreOptionsMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMoreOptionsMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMoreOptionsMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showPostRating: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showPostRating,
        mobileParamName: LEGACYSTYLEPARAMS.showPostRatingMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showCommentCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showCommentCount,
        mobileParamName: LEGACYSTYLEPARAMS.showCommentCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showViewCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showViewCount,
        mobileParamName: LEGACYSTYLEPARAMS.showViewCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  showLikeCount: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showLikeCount,
        mobileParamName: LEGACYSTYLEPARAMS.showLikeCountMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  // #endregion

  // #region Display - Menu

  showMenu: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenu,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuLoginButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuLoginButton,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuLoginButtonMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuCategoryLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuCategoryLabels,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuCategoryLabelsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showMenuSearch: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showMenuSearch,
        mobileParamName: LEGACYSTYLEPARAMS.showMenuSearchMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },

  // #endregion

  // #region Display - Share
  showShare: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShare,
        mobileParamName: LEGACYSTYLEPARAMS.showShareMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconFacebook: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconFacebook,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconFacebookMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconX: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconX,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconXMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconLinkedIn: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconLinkedIn,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconLinkedInMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconLink: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showShareIconLink,
        mobileParamName: LEGACYSTYLEPARAMS.showShareIconLinkMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showShareIconPrint: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showShareIconPrint),
  },
  showSharePluginPinterest: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showSharePluginPinterest),
  },
  showSharePluginX: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showSharePluginX),
  },
  // #endregion

  // #region Display - Related posts
  showRelatedPosts: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPosts,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsLabel,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsLabelMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsLabelType: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsLabelType,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsLabelTypeMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  showRelatedPostsSeeAllLink: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      resolveDefaultStyleParamValue({
        isMobile,
        getStyleParamValue,
        paramName: LEGACYSTYLEPARAMS.showRelatedPostsSeeAllLink,
        mobileParamName: LEGACYSTYLEPARAMS.showRelatedPostsSeeAllLinkMobile,
        dependencyParam: LEGACYSTYLEPARAMS.isMobileDisplaySettingsEnabled,
      }),
  },
  // #endregion

  // #region Design - Navigation
  navigationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextFont),
  },
  navigationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextColor),
  },
  navigationTextActiveColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationTextActiveColor),
  },
  navigationBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.navigationBackgroundColor),
  },
  // #endregion
  // #region Design - Text style and color
  textParagraphFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textParagraphFont),
  },
  textParagraphColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textParagraphColor),
  },
  textQuoteFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textQuoteFont),
  },
  textQuoteColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textQuoteColor),
  },
  textLinksAndHashtagsColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textLinksAndHashtagsColor),
  },
  textH1Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH1Font),
  },
  textH1Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH1Color),
  },
  textH2Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH2Font),
  },
  textH2Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH2Color),
  },
  textH3Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH3Font),
  },
  textH3Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH3Color),
  },
  textH4Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH4Font),
  },
  textH4Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH4Color),
  },
  textH5Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH5Font),
  },
  textH5Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH5Color),
  },
  textH6Font: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH6Font),
  },
  textH6Color: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textH6Color),
  },
  // #endregion
  // #region Design - Ratings
  ratingsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsTextFont),
  },
  ratingsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsTextColor),
  },
  ratingsStarFilledColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsStarFilledColor),
  },
  ratingsStarEmptyColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.ratingsStarEmptyColor),
  },
  // #endregion
  // #region Design - Tags
  showTagsLabel: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.showTagsLabel),
  },
  tagLayout: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.tagLayout),
  },
  // #endregion
  // #region Design - Tags - Button
  buttonTagAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagAlignment),
  },
  buttonTagTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextFont),
  },
  buttonTagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextColor),
  },
  buttonTagTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagTextHoverColor),
  },
  buttonTagBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBackgroundColor),
  },
  buttonTagBackgroundHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBackgroundHoverColor),
  },
  buttonTagBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderColor),
  },
  buttonTagBorderHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderHoverColor),
  },
  buttonTagBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderWidth),
  },
  buttonTagBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagBorderRadius),
  },
  buttonTagVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagVerticalPadding),
  },
  buttonTagHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagHorizontalPadding),
  },
  buttonTagVerticalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagVerticalSpacing),
  },
  buttonTagHorizontalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.buttonTagHorizontalSpacing),
  },
  // #endregion
  // #region Design - Tags - Text
  textTagAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagAlignment),
  },
  textTagTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextFont),
  },
  textTagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextColor),
  },
  textTagTextHoverColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagTextHoverColor),
  },
  textTagVerticalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagVerticalSpacing),
  },
  textTagHorizontalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.textTagHorizontalSpacing),
  },
  // #endregion
  // #region Design - Background and buttons
  postBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBorderColor),
  },
  postBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBorderWidth),
  },
  postBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postBackgroundColor),
  },
  postAreaBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postAreaBackgroundColor),
  },
  postButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postButtonTextColor),
  },
  postButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ isMobile, getStyleParamValue }) =>
      getStyleParamValue(LEGACYSTYLEPARAMS.postButtonBackgroundColor),
  },
  // #endregion
} satisfies {
  [P in keyof IStylesParams]: Omit<IStyleParam<IStylesParams[P]>, 'name'>;
});
