import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  PostListWidgetPaginationType,
  postListWidgetStyleParams,
} from '@wix/communities-blog-client-common';
import { type AppState } from '../types';

type State = AppState['appSettings'];

export const getAppSettings = (state: AppState) => state.appSettings;

type ColorSetting = {
  params: {
    state: AppState;
    key: `style.colors.${string}`;
    fallback?: State['style']['colors'][string];
  };
  result: State['style']['colors'][string];
};

type FontSetting = {
  params: {
    state: AppState;
    key: `style.fonts.${string}`;
    fallback?: State['style']['fonts'][string];
  };
  result: State['style']['fonts'][string];
};

type NumberSetting = {
  params: {
    state: AppState;
    key: `style.numbers.${string}`;
    fallback?: State['style']['numbers'][string];
  };
  result: State['style']['numbers'][string];
};

type BooleanSetting = {
  params: {
    state: AppState;
    key: `style.booleans.${string}`;
    fallback?: State['style']['booleans'][string];
  };
  result: State['style']['booleans'][string];
};

export function getAppSettingsValue<T extends keyof State>(params: {
  state: AppState;
  key: T;
  fallback?: State[T];
}): State[T];
export function getAppSettingsValue(params: ColorSetting['params']): ColorSetting['result'];
export function getAppSettingsValue(params: FontSetting['params']): FontSetting['result'];
export function getAppSettingsValue(params: NumberSetting['params']): NumberSetting['result'];
export function getAppSettingsValue(params: BooleanSetting['params']): BooleanSetting['result'];
export function getAppSettingsValue({
  state,
  key,
  fallback = undefined,
}: {
  state: AppState;
  key: string;
  fallback?: any;
}) {
  return get(getAppSettings(state), key, fallback);
}

export const getAppSettingsNumber = (state: AppState, key: string, fallback: number = 1) =>
  getAppSettingsValue({ state, key: `style.numbers.${key}`, fallback });

export const getAppSettingsFont = (state: AppState, key: string, fallback: any = undefined) =>
  getAppSettingsValue({ state, key: `style.fonts.${key}`, fallback });

export const getPostListPaginationEnabled = createSelector(
  [(state: AppState) => state],
  (state) => {
    const paginationType = getAppSettingsValue({
      state,
      key: `style.numbers.${postListWidgetStyleParams.postListWidgetPaginationType.key}`,
      fallback: postListWidgetStyleParams.postListWidgetPaginationType
        .defaultValue as unknown as number,
    });

    return String(paginationType) !== PostListWidgetPaginationType.NumberOfPosts;
  },
);
